import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dashboardData: {},
  caseLogData: {},
  disputeCaseStatus: {},
  disputeCaseType: {},
  commanHeader: "",
  selectedStore: null,
};

const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState,
  reducers: {
    setLogout: () => initialState,

    setDashBoardData: (state, action) => {
      return {
        ...state,
        dashboardData: action.payload,
      };
    },

    setCaseLogData: (state, action) => {
      return {
        ...state,
        caseLogData: action.payload,
      };
    },
    setDisputeCaseStatus: (state, action) => {
      return {
        ...state,
        disputeCaseStatus: action.payload,
      };
    },
    setDisputeCaseType: (state, action) => {
      return {
        ...state,
        disputeCaseType: action.payload,
      };
    },
    setCommanHeader: (state, action) => {
      return {
        ...state,
        commanHeader: action.payload,
      };
    },
    setSelectedSotreForRedux: (state, action) => {
      return {
        ...state,
        selectedStore: action.payload,
      };
    },
  },
});

export const {
  setDashBoardData,
  setCaseLogData,
  setDisputeCaseStatus,
  setDisputeCaseType,
  setCommanHeader,
  setSelectedSotreForRedux,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
