import {
  Card,
  CardContent,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import TextFieldComponent from "../../TextFieldComponent";
import copyImage from "../../../assets/images/copy.png";
import { InfoToast } from "../../../utils/Toaster";
import { useDispatch, useSelector } from "react-redux";
import {
  resetAccStepper,
  setStoreCreation,
} from "../../../redux/slices/auth/signupSlice";
import {
  loginUser,
  storeUserDetail,
} from "../../../redux/thunk/auth/authThunk";
import { useNavigate } from "react-router-dom";

const Step3 = (props) => {
  const { buttonref } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { accStepper } = useSelector((state) => state.root.signup);
  // const { signUpCreds } = useSelector((state) => state.root.signup);
  const { accStepper, signUpCreds, timeStampForStore } = useSelector(
    (state) => state.root.signup,
  );

  const [fillData, setFillData] = React.useState({
    first_name: "Refund",
    last_name: "Stacker",
    email: accStepper?.data?.walmart_login_username,
  });

  useEffect(() => {
    if (timeStampForStore) {
      buttonref.current.click();
    } else if (accStepper?.success) {
      setFillData({
        ...fillData,
        email: accStepper?.data?.walmart_login_username,
      });
      // buttonref.current.click();
    }
  }, [accStepper, timeStampForStore]);

  const handleSubmit = (e) => {
    // dispatch(resetAccStepper());
    e.preventDefault();
    dispatch(setStoreCreation(true));
    let req = {
      apiData: signUpCreds,
      navigate: navigate,
    };
    // dispatch(storeUserDetail(req));
    buttonref.current.click();
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      InfoToast("Copied to clipboard!");
    });
  };

  return (
    <>
      <Typography
        variant="headingXS"
        align="center"
        component="h3"
        sx={{ letterSpacing: "normal" }}
      >
        Grant Access
      </Typography>
      <Typography variant="bodyXS" align="center" component="p" sx={{ mb: 3 }}>
        Refund Stacker needs this permission to serve your business
      </Typography>
      <Grid container justifyContent="center">
        <Grid item md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <Typography
                variant="bodyL"
                component="h5"
                sx={{
                  fontWeight: 600,
                  mb: 3,
                }}
              >
                Create Seller Center Account
              </Typography>
              <Typography variant="bodyS" component="p">
                In order for your account manager to open cases on your behalf,
                they will need a Seller Center account.
              </Typography>
              <div className="apiPointList">
                <div className="thumb">1.</div>
                <div className="content">
                  <Typography>
                    Go to{" "}
                    <a
                      className="text-info"
                      style={{ textDecoration: "none" }}
                      href="https://google.com"
                      target="blank"
                    >
                      https://seller.walmart.com/admin/user-management
                    </a>{" "}
                  </Typography>
                </div>
              </div>
              <div className="apiPointList">
                <div className="thumb">2.</div>
                <div className="content">
                  <Typography>
                    Click <span style={{ fontWeight: 600 }}>Add New User</span>{" "}
                    and enter the email provided here.
                  </Typography>
                </div>
              </div>
              <div className="apiPointList">
                <div className="thumb">3.</div>
                <div className="content">
                  <Typography>
                    Enter the first and last name provided, and select{" "}
                    <span style={{ fontWeight: 600 }}> Read and Write</span> for
                    the role.
                  </Typography>
                </div>
              </div>
              <Typography variant="bodyS" component="p" sx={{ mt: 7, mb: 5 }}>
                Once completed, click{" "}
                <span style={{ fontWeight: 600 }}>Next</span> to
                start the sync and notify our team to start working our magic.
              </Typography>
              <Typography>
                <a
                  target="blank"
                  href="https://google.com"
                  className="text-info"
                  style={{
                    textDecoration: "none",
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                >
                  For a more detailed set of instructions, click here.
                </a>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Card
                style={{
                  boxShadow:
                    "0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)",
                }}
              >
                <CardContent>
                  <Typography
                    variant="bodyL"
                    component="h5"
                    sx={{
                      fontWeight: 600,
                      mb: 3,
                    }}
                  >
                    Grant Access
                  </Typography>
                  <TextFieldComponent
                    id="first_name"
                    name="first_name"
                    label="First name"
                    value={fillData?.first_name}
                    disabled
                    // onChange={handleInputChange}
                    // error={errors?.contact_name}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              copyToClipboard(fillData?.first_name)
                            }
                            edge="end"
                          >
                            <img
                              src={copyImage}
                              width={16}
                              height={16}
                              alt="copy to clipboard"
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextFieldComponent
                    id="last_name"
                    name="last_name"
                    label="Last name"
                    type="email"
                    value={fillData?.last_name}
                    // onChange={handleInputChange}
                    // error={errors?.email}
                    disabled
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => copyToClipboard(fillData?.last_name)}
                            edge="end"
                          >
                            <img
                              src={copyImage}
                              width={16}
                              height={16}
                              alt="copy to clipboard"
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextFieldComponent
                    id="email"
                    name="email"
                    label="Email address"
                    type="email"
                    value={fillData?.email}
                    disabled
                    // onChange={handleInputChange}
                    // error={errors?.email}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => copyToClipboard(fillData?.email)}
                            edge="end"
                          >
                            <img
                              src={copyImage}
                              width={16}
                              height={16}
                              alt="copy to clipboard"
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Next
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Step3;
