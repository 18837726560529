import { Chip, Tooltip } from "@mui/material";

export const ChipCard = ({ label, title }) => {
  const chipConfig = {
    New: { updatedLabel: "Awaiting", color: "warning" },
    "Not Disputable": { updatedLabel: "Denied", color: "error" },
    "Needs Info": { updatedLabel: "In Progress", color: "primary" },
    Lost: { updatedLabel: "Denied", color: "error" },
    "Case Submitted": { updatedLabel: "In Progress", color: "primary" },
    Won: { updatedLabel: "Refunded", color: "success" },
    Active: { color: "success", updatedLabel: "Active" },
    InActive: { color: "error", updatedLabel: "InActive" },
    "missing permissions": {
      color: "error",
      updatedLabel: "Missing Permissions",
    },
    "invalid credentials": {
      color: "warning",
      updatedLabel: "Invalid Credentials",
    },
    valid: { color: "success", updatedLabel: "Valid" },
  };

  const { color, updatedLabel } = chipConfig[label] || {};

  return (
    <Tooltip title={title ? title : ""}>
      <Chip
        label={updatedLabel}
        size="small"
        color={color}
        sx={{ cursor: "pointer" }}
      />
    </Tooltip>
  );
};
